import React, { useState, useEffect } from 'react';
import Button from "@mui/material/Button"
import { Card, CardContent, CardHeader, Divider } from '@mui/material';
import { Input as BaseInput } from '@mui/base/Input';
import { styled } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import { Cookie, ImageOffIcon, Zap } from "lucide-react"
import Cookies from 'js-cookie';
import Getauth from './auth';
import { GoogleMap , useJsApiLoader , StandaloneSearchBox } from '@react-google-maps/api';
import {useRef} from "react"
import axios from "axios";
import { photographyPackages } from "./data";
import { ChevronDown, ChevronUp } from "lucide-react";
import Alert from '@mui/material/Alert';
import { Navigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import RazorpayButton from './Payment';


export default function Booking() {
    const [alert, setAlert] = useState(false);
    const [status, setStatus] = useState('')
    const [alertContent, setAlertContent] = useState('');
    const [user , setUser] = useState(Getauth()) 
    const locationref = useRef(null);
    const [address , setAddress]= useState('');
    const [name, setName] = useState(Cookies.get('fullname'));
    const [email, setEmail] = useState(Cookies.get('email'));
    const [mobile, setMobile] = useState(Cookies.get('phone'));
    const [id, setId] = useState(Cookies.get('id'));
    const [shootTime, setShootTime] = useState('');
    const [location, setLocation] = useState('');
    const [showAllFeatures, setShowAllFeatures] = useState({});
    const [loading, setLoading] = useState(false);


    const Package = JSON.parse(localStorage.getItem('Package'))
    const Category =localStorage.getItem("category")

    const toggleShowAllFeatures = (packageName) => {
      
      setShowAllFeatures((prev) => ({
        ...prev,
        [packageName]: !prev[packageName],
      }));
    };

    const{isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: 'AIzaSyCQ0pijnQxM-4bnOEDLrhlJTqjw73oH660',
        libraries:["places"]
    })

    //payments
    const displayRazorpay = async (event) => {
      event.preventDefault();
      setLoading(true)

      console.log( Package.offerPrice/2)

      const { data: { key } } = await axios.get("https://memow-web-backend-production.up.railway.app/api/v1/payment/get-key")
  
      const data  = await axios.post("https://memow-web-backend-production.up.railway.app/api/v1/payment/checkout", {
        "amount" : Package.offerPrice? Package.offerPrice/2 : Package.originalPrice/2
      })
      console.log("data",data.data.data.id)
  
      const options = {
        key: key, // Enter the Key ID generated from the Dashboard
        amount: Package.offerPrice? Package.offerPrice : Package.originalPrice * 50, // Amount is in currency subunits. Default currency is INR.
        currency: 'INR',
        name: 'Memowries', // Your business name
        description: `${Package.name},${Category}`,
        image: 'https://drive.google.com/file/d/1RoMq7L6UCB0bbqBqlPtCJs2DvMfZVKSI/view?usp=sharing',
        order_id: data.data.data.id,
        handler: async function (response){

          try{
            setLoading(true)
          const data =await axios.post("https://memow-web-backend-production.up.railway.app/api/v1/payment/verify-payment",response)
          console.log(data)
          if(!data){
            window.alert("Payment Not Completed")
          }else{
            await initiateBooking(data.data.data._id , "advance-paid")
            
          }
          
          }catch(e){
            console.log(e)
          }
          
      },
        
        prefill: {
          name: name, 
          email: email,
          contact: mobile,
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#F0C651',
        },
      };
      setLoading(false)
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    };

    async function initiateBooking(  paymentId , paymentStatus) {
      console.log(paymentId)
      console.log(paymentStatus)
        
        setLoading(true)
        try {
            const data = {
                "paymentStatus": paymentStatus,
                "paymentId":paymentId,
                "mobileNo":mobile,
                "email":email,
                "offeredPrice": Package.offerPrice? Package.offerPrice : Package.originalPrice,
                "date": `${shootTime}:00Z`,
                "clerkId": `${id}`,
                "originalPrice":Package.originalPrice,
                "fullName":name,
                "bookingType": Category,
                "packageType":Package.name,
                "lat":`${address[0].geometry.viewport.Gh.hi}`,
                "lng":`${address[0].geometry.viewport.ei.hi}`,
                "address":`${location},${address[0].formatted_address}`,
            }
          const response = await axios.post('https://memow-web-backend-production.up.railway.app/api/v1/booking/create-booking',data );
          console.log("api fetched")
          console.log(response);
          setAlertContent("Data sucessfuly saved");
          setStatus(true)
          setAlert(true);
        } catch (error) {
          setAlertContent("This is a filled error Alert.");
          setStatus(false)
          setAlert(true);
          setLoading(false)
          console.error(error);
        }
      }

    const handleOnPlaceChange = ()=>{
        setAddress(locationref.current.getPlaces());
       }
    console.log(`${isLoaded} maps`)
    console.log(address[0]    )


    const Input = React.forwardRef(function CustomInput(props, ref) {
        return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
      });

  return (
    <div className="container mx-auto p-4 md:h-screen max-w-6xl content-center px-4 py-8 my-10">
      {alert ? status ? <Alert variant="filled" severity='success'>{alertContent}</Alert> : <Alert variant="filled" severity='error'>{alertContent}</Alert> : <></> }
      {status==true && (<Navigate to="/past-booking" replace={true} />)}
      <h1 className="text-4xl font-bold mb-2 text-center mt-4">Booking
      <span className="text-[#c29112]">.</span>
      </h1>
      <p className="text-center mb-8">
        Enter your details quickly <Zap className="inline-block w-5 h-5" />, and reserve your slot.
      </p>

      <div className="flex flex-col md:flex-row gap-8">
        <Card className="md:w-1/3">
          <CardContent className="p-6">
            <h2 className="text-xl font-bold mb-2">{Category}:</h2>
            <h3 className="text-2xl font-bold mb-4">{Package.name}<span className="text-[#c29112]">.</span></h3>
            <p className="mb-4 text-sm">{Package.description}</p>
                <ul className="list-disc list-inside space-y-1 text-sm text-gray-600 mb-4">
                {Package.features
                  .slice(
                    0,
                    showAllFeatures[Package.name] ? Package.features.length : 3
                  )
                  .map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                <button
                  className="flex text-black text-sm pb-3 mt-0 pt-0"
                  onClick={() => toggleShowAllFeatures(Package.name)}
                >
                  {showAllFeatures[Package.name] ? "View Less" : "View More"}
                  {showAllFeatures[Package.name] ?  <ChevronUp className="ml-2 w-5 h-5 transition-transform " /> : <ChevronDown className="ml-2 w-5 h-5 transition-transform "/>}
                </button>
            </ul>
            <p className="mb-2 text-sm">Starts at:</p>
            <p className="text-2xl font-bold mb-4">
              <span className="line-through text-gray-400 text-lg mr-2"> {Package.offerPrice?`₹${Package.originalPrice}` : ""}</span>
              ₹ {Package.offerPrice ? Package.offerPrice : Package.originalPrice}
            </p>
          </CardContent>
        </Card>

        <div className="md:w-2/3">
          <h2 className="text-2xl font-bold mb-6">
            Tell us about you and your plan <span className="text-yellow-400">👑</span>:
          </h2>
          {/* <RazorpayButton amount={Package.offerPrice} name={name} email={email} mobile={mobile}/> */}
          <form className="space-y-4" onSubmit={displayRazorpay}>
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <InputLabel htmlFor="name">Name</InputLabel>
                <input class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
                name="name" id="name" type="text" placeholder="First Last"  value={name} onChange={(e)=>setName(e.target.value)} defaultValue={user ? email : ""} required/>
              </div>
              <div>
                <InputLabel htmlFor="email">Email</InputLabel>
                <input  class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
                 name="email" id="email" type="email" placeholder="name@email.com"  value={email} onChange={(e)=>setEmail(e.target.value) } defaultValue={user ? email : ""} required/>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <InputLabel htmlFor="mobile">
                  Mobile <span className="text-red-500">WhatsApp Number</span>
                </InputLabel>
                <input  class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
                 name="mobile" id="mobile" placeholder="91999xxxxx99" value={mobile} onChange={(e)=>setMobile(e.target.value)} defaultValue={user ? mobile : ""} required/>
              </div>
              <div>
                <InputLabel htmlFor="shoot-time">Time of Shoot</InputLabel>
                <input  class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
                 name="shoot-time"id="shoot-time" type="datetime-local" value={shootTime} onChange={(e)=>setShootTime(e.target.value)} required/>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <InputLabel htmlFor="city">
                  City <span className="text-red-500">Verify Price Change</span>
                </InputLabel>
                {isLoaded &&
                <StandaloneSearchBox
                onLoad={(ref)=> locationref.current = ref}
                onPlacesChanged={handleOnPlaceChange}>
                <input  class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
                 id="location" placeholder="Search Your City"/>
                </StandaloneSearchBox>}
              </div>
              <div>
                <InputLabel htmlFor="location">
                  Location <span className="text-gray-400">Where to meet?</span>
                </InputLabel>
                <input class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
                 id="location" placeholder="Hotel / Apartment / Street"  value={location} onChange={(e)=>setLocation(e.target.value)} required/>
              </div>
            </div>

            {loading ?  <LoadingButton loading variant="contained" className="w-full bg-red-500 hover:bg-green-600 text-white">Submit</LoadingButton> : <Button type="submit" variant="contained" color='success' className="w-full bg-red-500 hover:bg-green-600 text-white">Book Advance Now</Button>}
            
          </form>
          <div className="mt-4 text-sm text-gray-600">
            <p>* Booking Subject to Availability.</p>
            <p>
              * By proceeding, you agree to{" "}
              <a href="terms-and-conditions" className="text-blue-500 hover:underline">
                Terms and Conditions
              </a>{" "}
              of this booking.
            </p>
            <p>
              * You also agree to{" "}
              <a href="privacy-policy" className="text-blue-500 hover:underline">
                Memowries Privacy Policy
              </a>{" "}
              and provide consent to receive Whatsapp communications.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}


const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const InputElement = styled('input')(
    ({ theme }) => `
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );