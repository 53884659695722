import { useUser } from '@clerk/clerk-react'
import Cookies from 'js-cookie';

export default  function Getauth() {
  const { isSignedIn, user, isLoaded } = useUser()

  if (!isLoaded) {
    return null
  }
  Cookies.set('fullname', user.fullName, { expires: 7 }); 
  Cookies.set('email', user.emailAddresses[0].emailAddress, { expires: 7 });
  Cookies.set('phone', user.phoneNumbers[0].phoneNumber, { expires: 7 });
  Cookies.set('id', user.id, { expires: 7 }); 
  return user
}