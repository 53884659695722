import React from 'react';
import './style.css'; // Import your CSS styles

const AfterBooking = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-title">Thank you for booking</h1>
      <p className="coming-soon-text">Your booking has initiate</p>
    </div>
  );
};

export default AfterBooking;
