'use client'
import React from 'react';
import IPhone15Pro from './assets/IPhone_15_Pro.png';
import wedding from './assets/Wedding.png';
import preWedding from './assets/Pre_Wedding.png';
import vacation from './assets/Vacation.png';
import brandingPromotion from './assets/Branding_Promotion.png';
import profileHeadshot from './assets/Profile_Headshot.png';
import event from './assets/Event.png';
import maternity from './assets/Maternity.png';
import newborn from './assets/Newborn.png';
import portfolio from './assets/Portfolio.png';
import { useState, useEffect } from 'react'
import { ChevronDown, ChevronUp, Camera, Users, Palette, Award } from 'lucide-react'
import { TypeAnimation } from 'react-type-animation';

const Services = () => {
  const services = [
    {
      title: "Instant Shoots",
      icon: Camera,
      description: "MEMOW offers instant access to photographers and videographers, ensuring you can book a shoot at a moment's notice.",
      features: [
        "Speed: Quick and easy booking process for last-minute projects",
        "Quality: Professional-grade content, ready when you are",
        "Flexibility: Available for a variety of needs, from events to product photography"
      ]
    },
    {
      title: "Personalized Services",
      icon: Users,
      description: "MEMOW provides personalized services that cater to your specific creative vision.",
      features: [
        "Customization: Work closely with top talent to bring your unique ideas to life",
        "Expertise: Access to specialized professionals who understand your niche",
        "Satisfaction: Ensure the final product perfectly matches your expectations"
      ]
    },
    {
      title: "Creative Space",
      icon: Palette,
      description: "MEMOW isn't just a platform-it's a community. Showcase your portfolio, connect with potential clients, and collaborate with other creatives.",
      features: [
        "Visibility: Get your work seen by a broader audience.",
        "Opportunities: Connect with clients and other professionals for future projects.",
        "Growth: Build your brand and expand your creative network."
      ]
    },
    {
      title: "Why Choose MEMOW",
      icon: Award,
      description: "Experience the difference with our premium photography services.",
      features: [
        "Trusted Talent: Only the best photographers and videographers are featured on MEMOW, ensuring top-tier quality for every project.",
        "Affordability: Competitive pricing without compromising on quality.",
        "Community-Focused: A platform built to empower creatives and connect them with clients who value their work."
      ]
    }
  ]

  const categories = [
    { name: "Wedding", image: wedding, description: "Majestic animals in their natural habitats" },
    { name: "Pre Wedding", image: preWedding, description: "Adorable companions in domestic settings" },
    { name: "Vacation", image: vacation, description: "Fascinating creatures from the depths of the ocean" },
    { name: "Branding/Promotion", image: brandingPromotion, description: "Colorful avian species from around the world" },
    { name: "Profile/Headshot", image: profileHeadshot, description: "Tiny marvels of the natural world" },
    { name: "Event", image: event, description: "Charming creatures from rural landscapes" },
    { name: "Maternity", image: maternity, description: "Charming creatures from rural landscapes" },
    { name: "Newborn", image: newborn, description: "Charming creatures from rural landscapes" },
    { name: "Portfolio", image: portfolio, description: "Charming creatures from rural landscapes" },
  ]

  const [isExpanded, setIsExpanded] = useState(false)
  const initialDisplayCount = 3
  const displayedCategories = isExpanded ? categories : categories.slice(0, initialDisplayCount)

  useEffect(() => {
    const heroSection = document.querySelector('.hero');
    const heroElements = document.querySelectorAll('.hero-image, .hero-title');

    const observerOptions = {
      threshold: 0.3, // Trigger when 30% of the section is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add 'visible' class to trigger the animation when section is in view
          heroSection.classList.add('visible');
          heroElements.forEach((el) => el.classList.add('visible'));
        }
      });
    }, observerOptions);

    // Start observing the hero section
    observer.observe(heroSection);

    // Cleanup observer when component is unmounted
    return () => observer.disconnect();
  }, []);

  return (
    <div className='bg-gradient-to-b from-amber-50/50 to-white'>
      <section className="hero mt-20 md:mt-0">
        <main className="container mx-auto px-4 py-8 md:py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div className="space-y-6">
              <h1 className="text-4xl md:text-5xl font-bold leading-tight ">
                Services We Offer, <TypeAnimation
                    sequence={[

                      'Makes Your Occation Special.',
                      1000, 
                      'Makes Your Events Special.',
                      1000,
                      'Makes Your Parties Special.',
                      1000,
                      'Makes Your Vacation Special.',
                      1000
                    ]}
                    wrapper="span"
                    speed={15}
                    className='text-4xl md:text-5xl font-bold leading-tight text-[#c29112]'
                    repeat={Infinity}
                   />
              </h1>
            </div>
            {/* Image Section */}
            <div className="hero-image">
              <img class="object-scale-up" src={IPhone15Pro} alt="Mémow Hero Image" />
            </div>
          </div>
        </main>
      </section>

      <section className="py-16 px-4">
      <div className="container mx-auto max-w-6xl">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-[#c29112] flex items-center gap-4">
          <span className="w-1 h-12 bg-[#c29112] rounded-full" />
          See what we have to offer
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {services.map((service, index) => (
            <div 
              key={index} 
              className="group bg-white border border-amber-100 rounded-lg p-6 hover:shadow-lg transition-all duration-300"
            >
              <div className="mb-6">
                <div className="flex items-center gap-4">
                  <div className="p-3 rounded-xl bg-amber-100/50 group-hover:bg-amber-100 transition-colors">
                    <service.icon className="w-6 h-6 text-[#c29112]" />
                  </div>
                  <h3 className="text-xl font-semibold text-[#c29112]">{service.title}</h3>
                </div>
              </div>
              <div className="space-y-4">
                <p className="text-base text-gray-600">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-start gap-2 text-sm text-gray-500">
                      <span className="w-1.5 h-1.5 rounded-full bg-amber-500 mt-1.5 shrink-0" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>

      {/* Categories Section */}
      <section className="py-12">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 text-[#c29112] flex items-center gap-4">
          <span className="w-1 h-12 bg-[#c29112] rounded-full" />
          Explore Our Categories
        </h2>
        
        <div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 transition-all duration-500 ease-in-out"
          aria-live="polite"
        >
          {displayedCategories.map((category, index) => (
            <div 
              key={index} 
              className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105"
              style={{ 
                animation: `fadeIn 0.5s ease-out ${index * 0.1}s`,
                opacity: 0,
                animationFillMode: 'forwards'
              }}
            >
              <img
                src={category.image}
                alt={category.name}
                className="w-full h-48 object-cover"
                loading={index < initialDisplayCount ? 'eager' : 'lazy'}
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{category.name}</h3>
                {/* <p className="text-gray-600">{category.description}</p> */}
              </div>
            </div>
          ))}
        </div>

        {categories.length > initialDisplayCount && (
          <div className="flex justify-center mt-8">
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="group flex items-center gap-2 px-6 py-3 bg-white border border-gray-200 rounded-full shadow-sm hover:shadow-md transition-all duration-300 text-gray-700 hover:text-gray-900"
              aria-expanded={isExpanded}
              aria-controls="categories-grid"
            >
              <span>{isExpanded ? 'View Less' : 'View More'}</span>
              {isExpanded ? (
                <ChevronUp className="w-5 h-5 transition-transform group-hover:-translate-y-0.5" />
              ) : (
                <ChevronDown className="w-5 h-5 transition-transform group-hover:translate-y-0.5" />
              )}
            </button>
          </div>
        )}
      </div>

      <style jsx global>{`
        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
      `}</style>
    </section>
    </div>
  );
};

export default Services;
