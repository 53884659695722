export default function TermsAndConditions() {
    return (
      <div className="w-full mt-20">
        <header className="p-6 mb-6">
          <h1 className="text-4xl font-bold text-center mb-2">Terms and Conditions.</h1>
          <p className="text-center text-gray-700">
            Just a little legal things 🙂
          </p>
        </header>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-6">
          <section>
            <p className="font-bold">Welcome to Memow!</p>
            <p className="mt-4">
              These terms and conditions outline the rules and regulations for the use of Moinak Naskar's Website, located at https://www.memowries.com.
            </p>
            <p className="mt-4">
              By accessing this website we assume you accept these terms and conditions, Refund Policy and Cancellation Policy. Do not continue to use Memow if you do not agree to these policies and to take all of the terms and conditions stated on this page.
            </p>
            <p className="mt-4">
              The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.
            </p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">Photography Service Terms</h2>
            <p className="font-bold">Scope of Services:</p>
            <p>The Company will provide photography services to the Client as specified in the Client's booking agreement, subject to the Photographer's availability. The Photographer will make every reasonable effort to capture high-quality images. The Client is expected to cooperate with the Photographer for posing and dressing up within the duration of the photography session as per the booking agreement.</p>
            
            <p className="font-bold mt-4">Payment:</p>
            <p>A deposit is required to secure the date of the photography session. The deposit is non-refundable, except in the case of cancellation within 48 hours of booking, in which case the deposit will be refunded in full within 2 working days from cancellation. The balance is due in full on or before the day of the photography session.</p>
            
            <p className="font-bold mt-4">Cancellation/Rescheduling:</p>
            <p>The Client may cancel or reschedule the photography session within 48 hours of booking, without forfeiting the deposit. In the event of inclement weather or other unforeseen circumstances, the Company reserves the right to reschedule the photography session.</p>
            
            <p className="font-bold mt-4">Copyright and Usage:</p>
            <p>The Company retains the copyright to all images created during the photography session. The Client is granted a non-exclusive, non-transferable license to use the images for personal use only. The Client may not sell or otherwise transfer the images to any third party.</p>
            
            <p className="font-bold mt-4">Model Release:</p>
            <p>The Client grants the Company permission to use any images taken during the photography session for advertising, promotional, and display purposes. The Client agrees to obtain written permission from any individuals who appear in the images.</p>
            
            <p className="font-bold mt-4">Liability:</p>
            <p>The Company will take every reasonable care to prevent loss or damage to the Client's property, including any images created during the photography session. However, the Company assumes no liability for any loss or damage to the Client's property. The Client assumes all liability for any damage or injury caused by the Client or their guests during the photography session.</p>
            
            <p className="font-bold mt-4">Notification:</p>
            <p>The Company will send alerts via WhatsApp or SMS to the Client regarding the scheduled session, and it is the Client's responsibility to be on a network or Wi-Fi to receive the alerts. Delivery of the edited images will be via SMS or WhatsApp, and the delivery time will be as per the plan chosen by the Client.</p>
            
            <p className="font-bold mt-4">Force Majeure:</p>
            <p>The Company shall not be liable for any failure or delay in performing its obligations under this agreement if such failure or delay is caused by an event beyond the Company's reasonable control, including but not limited to acts of God, natural disasters, war, terrorism, or labor disputes.</p>
            
            <p className="font-bold mt-4">Governing Law:</p>
            <p>This agreement shall be governed by the laws of the state of Chhattisgarh, India.</p>
            
            <p className="font-bold mt-4">Delivery:</p>
            <p>The Company will deliver the number of edited images as per the plan chosen by the Client. All images will not be edited. The Photographer does not provide raw images clicked during the photography session unless agreed upon in writing and may be available on extra charges.</p>
            
            <p className="font-bold mt-4">Tip / Extra Payments Outside of the Contract:</p>
            <p>Any additional payment to the photographer in cash or money transfer for additional services, add-ons is strictly prohibited and will be deemed as a breach of this agreement.</p>
            
            <p className="font-bold mt-4">Entire Agreement:</p>
            <p>This agreement constitutes the entire understanding between the Company and the Client and supersedes all prior negotiations, understandings, and agreements between the parties.</p>
            
            <p className="mt-4">By accepting the terms and conditions, the Client acknowledges that they have read, understood, and agree to be bound by this agreement.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">Cookies</h2>
            <p>We employ the use of cookies. By accessing Memow, you agreed to use cookies in agreement with the Moinak Naskar's Privacy Policy.</p>
            <p className="mt-4">Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">License</h2>
            <p>Unless otherwise stated, Moinak Naskar and/or its licensors own the intellectual property rights for all material on Memow. All intellectual property rights are reserved. You may access this from Memow for your own personal use subjected to restrictions set in these terms and conditions.</p>
            <p className="mt-4">You must not:</p>
            <ul className="list-disc list-inside mt-2 space-y-2">
              <li>Republish material from Memow</li>
              <li>Sell, rent or sub-license material from Memoew</li>
              <li>Reproduce, duplicate or copy material from Memow</li>
              <li>Redistribute content from Memow</li>
            </ul>
            <p className="mt-4">This Agreement shall begin on the date hereof.</p>
            <p className="mt-4">Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Moinak Naskar does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Memow,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Memow shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.</p>
            <p className="mt-4">Moinak Naskar reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
            <p className="mt-4">You warrant and represent that:</p>
            <ul className="list-disc list-inside mt-2 space-y-2">
              <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
              <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
              <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
              <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
            </ul>
            <p className="mt-4">You hereby grant Moinak Naskar a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">Hyperlinking to our Content</h2>
            <p>The following organizations may link to our Website without prior written approval:</p>
            <ul className="list-disc list-inside mt-2 space-y-2">
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
              <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
            </ul>
            <p className="mt-4">These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>
            <p className="mt-4">We may consider and approve other link requests from the following types of organizations:</p>
            <ul className="list-disc list-inside mt-2 space-y-2">
              <li>commonly-known consumer and/or business information sources;</li>
              <li>dot.com community sites;</li>
              <li>associations or other groups representing charities;</li>
              <li>online directory distributors;</li>
              <li>internet portals;</li>
              <li>accounting, law and consulting firms; and</li>
              <li>educational institutions and trade associations.</li>
            </ul>
            <p className="mt-4">We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Memow; and (d) the link is in the context of general resource information.</p>
            <p className="mt-4">These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.</p>
            <p className="mt-4">If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Memow Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>
            <p className="mt-4">Approved organizations may hyperlink to our Website as follows:</p>
            <ul className="list-disc list-inside mt-2 space-y-2">
              <li>By use of our corporate name; or</li>
              <li>By use of the uniform resource locator being linked to; or</li>
              <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
            </ul>
            <p className="mt-4">No use of Memow's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">iFrames</h2>
            <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">Content Liability</h2>
            <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">Your Privacy</h2>
            <p>Please read Privacy Policy</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">Reservation of Rights</h2>
            <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4 bg-[#c29112] text-white p-2">Removal of links from our website</h2>
            <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>
            <p className="mt-4">We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>
          </section>
  
          <section>
            <h2 className="text-2xl font-bold mt-8 mb-4  bg-[#c29112] text-white p-2">Disclaimer</h2>
            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>
            <ul className="list-disc list-inside mt-2 space-y-2">
              <li>limit or exclude our or your liability for death or personal injury;</li>
              <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
              <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
              <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
            </ul>
            <p className="mt-4">The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>
            <p className="mt-4">As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
          </section>
        </div>
      </div>
    )
  }