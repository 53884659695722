import React from 'react';
import ReactDOM from 'react-dom/client';
import './output.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ClerkProvider } from '@clerk/clerk-react'
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
const PUBLISHABLE_KEY = process.env.REACT_CLERK_PUBLISHABLE_KEY || 'pk_test_Y29ycmVjdC1vcG9zc3VtLTkwLmNsZXJrLmFjY291bnRzLmRldiQ'
console.log(PUBLISHABLE_KEY) 

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key") 
}
root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <App />
    </ClerkProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
