import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { MessageSquare } from "lucide-react"
import Getauth from './auth';
import Cookies from 'js-cookie';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';

export default function Contactus() {
  const [user , setUser] = useState(Getauth()) 
  const [alert, setAlert] = useState(false);
  const [status, setStatus] = useState('')
  const [alertContent, setAlertContent] = useState('');
  const [name, setName] = useState(Cookies.get('fullname'));
  const [email, setEmail] = useState(Cookies.get('email'));
  const [mobile, setMobile] = useState(Cookies.get('phone'));
  const [text, setText] = useState("")
  const [Concern, setConcern] = useState("");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(Cookies.get('id'));

  async function initiateContact(event) {
    setLoading(true)
    event.preventDefault();
    try {
        const data = {
          "email":email,
          "name":name,
          "concern":Concern,
          "clerkId":`${id}`,
          "message":text,
          "mobileNo":mobile
      }
      const response = await axios.post('https://memow-web-backend-production.up.railway.app/api/v1/contact/create-contact',data );
      setAlertContent("Data sucessfuly saved");
      setStatus(true)
      setAlert(true);
      console.log("api fetched")
      console.log(response);
      setLoading(false)
    } catch (error) {
      setAlertContent("This is a filled error Alert.");
      setStatus(false)
      setAlert(true);
      setLoading(false)
      console.error(error);
    }
  }
 
  return (
    <div className="container mx-auto px-4 py-8 mt-10">
      {alert ? status ? <Alert variant="filled" severity='success'>{alertContent}</Alert> : <Alert variant="filled" severity='error'>{alertContent}</Alert> : <></> }
      <h1 className="text-4xl font-bold text-center mb-2 mt-8 ">Contact<span className="text-[#c29112]">.</span></h1>
      <p className="text-center text-gray-600 mb-12">Quickly let us know how can we help you.</p>
      
      <div className="grid md:grid-cols-4 gap-8">
        <div className="md:col-span-1 space-y-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">CUSTOMER CARE:</h2>
            <p className="mb-2"><span className="font-semibold">Phone:</span>+91 87777 29704</p>
            <p className="mb-2"><span className="font-semibold">Email:</span>connect@memowries.com</p>
            <p className="mb-2"><span className="font-semibold">WhatsApp:</span></p>
            <Button variant="contained" color='success' className="bg-green-500 text-white hover:bg-green-600">
              <MessageSquare className="mr-2 h-4 w-4" /> Send Message
            </Button>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Our Offices</h2>
            <div className="mb-4">
              <h3 className="font-semibold">Kolkata</h3>
              <p className="text-sm text-gray-600">
              9C8G+HF2 Baruipur, Kolkata - 700144
              </p>
            </div>
            <div>
              <h3 className="font-semibold">Bangalore</h3>
              <p className="text-sm text-gray-600">
              187 Chowheshwari Nilaya, Bangalore - 560035, Karnataka
              </p>
            </div>
          </div>
        </div>
        
        <div className="md:col-span-3 bg-white p-10 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Write to us, and we will get back to you asap.</h2>
          <form className="space-y-4" onSubmit={initiateContact}>
            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Name</label>
                <input class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"  
                name="name" id="name" type="text" placeholder="First Last"  value={name} onChange={(e)=>setName(e.target.value)} 
                aria-label="Demo input" required/>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                <input class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
                name="email" id="email" type="email" placeholder="name@email.com"  value={email} onChange={(e)=>setEmail(e.target.value) } 
                defaultValue={user ? user.emailAddresses[0].emailAddress : ""} required/>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="mobile" className="block text-sm font-medium text-gray-700 mb-1">Mobile</label>
              <input class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
              name="mobile" id="mobile" placeholder="91999xxxxx99" value={mobile} onChange={(e)=>setMobile(e.target.value)} 
              defaultValue={user ? user.phoneNumbers[0].phoneNumber : ""} required/>
            </div>
            <div>
              <label htmlFor="concern" className="block text-sm font-medium text-gray-700 mb-1">Your Concern</label>
              <select class="w-full bg-white placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow" 
              value={Concern} onChange={(e)=>setConcern(e.target.value)} 
              id="concern" required>
                <option value="Personalized Shoot Plan" selected>Personalized Shoot Plan</option>
                <option value="Pricing Inquiry">Pricing Inquiry</option>
                <option value="Booking Information">Booking Information</option>
                <option value="Other">Other</option>
              </select>

            </div>
            </div>
             
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Your Message</label>
              <textarea placeholder="Describe your concern in details" class="border border-border rounded p-2 mt-4 w-full" rows="4" required 
              value={text} onChange={(e)=>setText(e.target.value)}></textarea>
            </div>
            {loading ?  <LoadingButton loading variant="contained" className="w-full bg-red-500 hover:bg-green-600 text-white">Submit</LoadingButton> : <Button type="submit" variant="contained" className="w-full bg-red-500 text-white hover:bg-green-600">Send Message</Button>}
          </form>
        </div>
      </div>
    </div>
  )
}

const blue = {
    100: '#DAECFF',
    200: '#80BFFF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const InputElement = styled('input')(
    ({ theme }) => `
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );