import Button from "@mui/material/Button";
import { Card, CardContent } from "@mui/material";
import { useState, useEffect } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Link } from "react-router-dom";
import { SignedIn, SignedOut, SignInButton } from "@clerk/clerk-react";
import { photographyPackages } from "./data";
import { styled } from "@mui/material/styles";


export default function Component({ category }) {
  const [categorys, setCategory] = useState("");
  const [expandedPackage, setExpandedPackage] = useState(null);
  const [showAllFeatures, setShowAllFeatures] = useState({});

  useEffect(() => {
    if (category === "") {
      console.log("empty");
      setCategory(localStorage.getItem("category"));
    } else {
      console.log("full");
      localStorage.setItem("category", category);
      setCategory(category);
    }
  }, [category]);

  const data = photographyPackages[categorys];

  const togglePackage = (packageName) => {
    setExpandedPackage(expandedPackage === packageName ? null : packageName);
  };

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#c29112",
      color: "white",
    },
  }));

  const toggleShowAllFeatures = (packageName) => {
    setShowAllFeatures((prev) => ({
      ...prev,
      [packageName]: !prev[packageName],
    }));
  };

  return (
    <div className="container mx-auto px-4 py-8 my-20">
      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-2">
          {data ? data.title : ""}
          <span className="text-[#c29112]">.</span>
        </h1>
        <p className="text-gray-600">{data ? data.oneline : ""}</p>
      </header>

      <div className="flex flex-col md:flex-row gap-8">
      <aside className="md:w-1/4 md:fixed md:top-60 md:bottom-0 md:overflow-y-auto">
          <nav className="space-y-2">
            <ColorButton className="w-full justify-start hover:bg-[#c29112] shadow">
              Pricing Plans
            </ColorButton>
            <a href="#ideas"><ColorButton className="w-full justify-start shadow">Ideas</ColorButton></a>
            <a href="#details"><ColorButton className="w-full justify-start shadow">Details</ColorButton></a>
          </nav>
        </aside>
        {/* <div className="md:w-3/4 md:ml-[25%]"> */}

        <main className="md:w-3/4 md:ml-[30%]">
          <div className="md:flex">
          <div>
          <p className="text-3xl font-bold">
            Pricing Plans<span className="text-[#c29112]">.</span>
          </p>
          <p className="text-gray-600 pb-5">
            Plans at a bargain 🤑 price, no need of a second opinion. 100% refunds on cancellation within 48 hrs of booking*.
          </p>
          </div>
          <SignedOut>
            <SignInButton mode="modal">
              <Button variant="outlined" color="error" className="bg-red-500 text-white">Enquire Now</Button>
              </SignInButton>
              </SignedOut>
              <SignedIn>
                <Link to="/contact-us" className="md:pd-2 mt-4 ml-3">
                <Button variant="outlined" color="error" className="bg-red-500 text-white">Enquire Now</Button>
                </Link>
                </SignedIn>
          </div>
          <Card className="mt-5 md:mt-0">
            <CardContent>
              <div className="space-y-6">
                {data &&
                  data.packages.map((pkg, index) => (
                    <div key={pkg.name} className="border-b pb-6 ">
                      <div
                        className="flex justify-between items-center mb-4 cursor-pointer"
                        onClick={() => togglePackage(pkg.name)}
                      >
                        <h4 className="text-xl font-semibold text-green-900 capitalize">
                          {pkg.name}<span className="text-[#c29112]">.</span>
                        </h4>
                        <div className="flex items-center">
                          <span className="text-gray-400 line-through mr-2">
                            {pkg.offerPrice?`₹${pkg.originalPrice}` : ""}
                          </span>
                          <span className="text-2xl font-bold">
                            ₹{pkg.offerPrice ? pkg.offerPrice : pkg.originalPrice}
                          </span>
                          {expandedPackage === pkg.name ? (
                            <ChevronUp className="ml-2" />
                          ) : (
                            <ChevronDown className="ml-2" />
                          )}
                        </div>
                      </div>

                      {expandedPackage === pkg.name && (
                        <div>
                          <p className="mb-2">{pkg.description}</p>
                          <ul className="list-disc list-inside space-y-1 text-sm text-gray-600 mb-4">
                            {pkg.features
                              .slice(
                                0,
                                showAllFeatures[pkg.name] ? pkg.features.length : 3
                              )
                              .map((feature, idx) => (
                                <li key={idx}>{feature}</li>
                              ))}
                          </ul>
                          {pkg.features.length > 3 && (
                            <button
                              className="flex text-black text-sm pb-3 mt-0 pt-0"
                              onClick={() => toggleShowAllFeatures(pkg.name)}
                            >
                              {showAllFeatures[pkg.name] ? "View Less" : "View More"}
                              {showAllFeatures[pkg.name] ?  <ChevronUp className="ml-2 w-5 h-5 transition-transform " /> : <ChevronDown className="ml-2 w-5 h-5 transition-transform "/>}
                            </button>
                          )}
                          <div className="flex justify-between items-center">
                            <SignedOut>
                              <SignInButton mode="modal">
                                <Button 
                                variant="contained"
                                color= 'success'
                                className="bg-black text-white hover:bg-[#c29112] cl">
                                  Book Now
                                </Button>
                              </SignInButton>
                            </SignedOut>
                            <SignedIn>
                              <Link to="/booking">
                                <Button
                                  variant="contained"
                                  color = 'success'
                                  className="bg-black text-white hover:bg-[#c29112]"
                                  onClick={()=>{localStorage.setItem('Package', JSON.stringify(pkg))}}
                                >
                                  Book Now
                                </Button>
                              </Link>
                            </SignedIn>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </CardContent>
          </Card>
          <div id="ideas" className="mt-8">
            <h3 className="text-2xl font-semibold mb-4">Ideas For Photoshoot<span className="text-r[#c29112]">.</span></h3>
            <p className="text-gray-600 mb-4">Some ideas 🤩 to inspire you, see how we have shot these high quality pictures -</p>
            <iframe src="https://widgets.commoninja.com/iframe/b7daf079-3da0-49a3-8603-ff62caa0cc5d" width="100%" height="525px" frameborder="0" scrolling="no"></iframe>
          </div>
          <div id="details" className="mt-8">
          <h3 className="text-2xl font-semibold mb-4">Photoshoot Description<span className="text-[#c29112]">.</span></h3>
          <p className="text-gray-600">{data ? data.description : ""}</p>
          </div>

        </main>
      </div>
    </div>
  );
}
