import React, {useEffect, useState} from 'react';
import IPhone13 from './assets/IPhone13.png';
import cameraPhotography from './assets/camera_photography.png';
import { motion } from "framer-motion"
import socialMediaIcon from './assets/social_media_icon.png';
import bookingIcon from './assets/booking_icon.png';
import aiToolsIcon from './assets/ai_tools_icon.png';
import phoneImage from './assets/phone_image.png';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { purple } from '@mui/material/colors';
import { Download, Sparkles, Star, ArrowUpRight } from "lucide-react"
import { Navigate } from 'react-router-dom';
import { SignedIn, SignedOut, SignInButton } from "@clerk/clerk-react";


const Home = () => {
  const [status, setStatus] = useState('')
  const [tos, setTos] = useState('')
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#c29112',
    '&:hover': {
      backgroundColor: '#c29112',
    },
  }));
  

  useEffect(() => {
    const heroSection = document.querySelector('.hero');
    const heroElements = document.querySelectorAll('.hero-image, .hero-title');

    const observerOptions = {
      threshold: 0.3, // Trigger when 30% of the section is in view
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add 'visible' class to trigger the animation when section is in view
          heroSection.classList.add('visible');
          heroElements.forEach((el) => el.classList.add('visible'));
        }
      });
    }, observerOptions);

    // Start observing the hero section
    observer.observe(heroSection);

    // Cleanup observer when component is unmounted
    return () => observer.disconnect();
  }, []);
  function Nav(to){
    setStatus(true)
    setTos(to)
  }

  return (
    <div className='bg-gradient-to-b from-amber-50/50 to-white'>
      {/* Hero Section */}
      {status==true && (<Navigate to={tos} replace={true} />)}
      <section className="hero mt-20 md:mt-0">
        {/* <div className="hero-container"> */}
          {/* Tagline */}
          <main className="container mx-auto px-4 py-8 md:py-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-6">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight ">
              Book Your Shoot, <span className="text-[#c29112]">Save Your Mémories Instantly</span>
              <span className="text-[#c29112]">.</span>
          </h1>
          <p className="text-base md:text-lg">
             Be a star yourself with our quality photoshoots at various occasions, moments and places that you travel. Let your photos speak your story, in style.
          </p>
          <div className=" space-x-0.5 md:space-x-4">
             <SignedIn>
                <Link to="/contact-us">
                  <ColorButton variant="contained"className="bg-red-500 text-white text-xs" >Talk to an Expert</ColorButton>
                </Link>
              </SignedIn>
              <SignedOut>
                <SignInButton mode="modal">
                  <ColorButton variant="contained"className="bg-red-500 text-white text-xs" >Talk to an Expert</ColorButton>
                </SignInButton>
              </SignedOut>
              <a href="#ideas">
                <Button variant="outlined" color="success" className="text-red-500 hover:bg-black-100 text-xs">Sample Shoots</Button>
              </a>
          </div>
          </div>
          {/* Image Section */}
          <div className="hero-image">
            <img class="object-scale-up" src={IPhone13} alt="Mémow Hero Image" />
          </div>
          </div>
          </main>
        {/* </div> */}
      </section>
      {/* About Us Section */}
      <section className="about-section">
      <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="grid gap-12 md:grid-cols-2 items-center mb-24"
        >
          <div className="space-y-6">
            <h1 className="text-4xl md:text-5xl font-bold tracking-tight text-[#c29112]">
              About Us
            </h1>
            <div className="space-y-4 text-sm md:text-lg text-gray-700">
              <p>
                <span className="font-semibold text-[#c29112]">MEMOW</span> is about to change the way you experience photography.
              </p>
              <p>
                We're building a platform that connects you with top photographers and videographers, 
                making high-quality content more accessible than ever.
              </p>
              <p>
                Whether you're looking for instant shoots, personalized services, or a creative space 
                to showcase your work — <span className="font-semibold text-[#c29112]">MEMOW</span> has 
                it all. Stay tuned for our launch!
              </p>
            </div>
            {/* <Link to='/services'> */}
            <ColorButton variant="contained"className="bg-red-500 text-white text-sm md:text-xs" onClick={()=>{Nav('/services')}}>Our Services <ArrowUpRight /></ColorButton>
            {/* </Link> */}
          </div>
          <motion.div 
            className="relative"
            initial={{ opacity: 0, scale: 0.95, rotate: -6 }}
            animate={{ opacity: 1, scale: 1, rotate: 0 }}
            transition={{ duration: 0.7, ease: "easeOut" }}
            whileHover={{ scale: 1.02 }}
          >
            <motion.div 
              className="absolute inset-0 bg-amber-900/10 rounded-2xl"
              initial={{ rotate: -12 }}
              animate={{ rotate: -6 }}
              transition={{ duration: 0.5 }}
              whileHover={{ rotate: -4 }}
            />
            <motion.div
              className="relative"
              whileHover={{ rotate: 2 }}
              transition={{ duration: 0.3 }}
            >
            <img 
              src={cameraPhotography}
              alt="Professional photographer at work"
              className="rounded-2xl shadow-xl relative"
              width={475}
              height={300}
            />
            </motion.div>
          </motion.div>
        </motion.section>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <h2 className="text-3xl md:text-4xl mb-12 text-[#c29112] flex items-center gap-4">
          {/* <span className="w-1 h-12 bg-[#c29112] rounded-full" /> */}
          Features We Are Here To Offer
        </h2>
        {/* <h2 className="features-title">Features We Are Here To Offer</h2> */}
        <div className="features-container">
          <div className="feature-card">
            <img src={socialMediaIcon} alt="Social Media Icon" className="feature-icon" />
            <h3>Integrated Social Media</h3>
            <p>Showcase, share, and monetize your creativity</p>
          </div>
          <div className="feature-card">
            <img src={bookingIcon} alt="Instant Booking Icon" className="feature-icon" />
            <h3>Instant Booking</h3>
            <p>Seamless and real-time photographer booking</p>
          </div>
          <div className="feature-card">
            <img src={aiToolsIcon} alt="AI Tools Icon" className="feature-icon" />
            <h3>AI-Powered Tools</h3>
            <p>Enhanced photo sorting, facial recognition, and much more</p>
          </div>
        </div>
      </section>
      {/* Grand Finale Sponsorship Section */}
      <iframe id="ideas" src="https://widgets.commoninja.com/iframe/b7daf079-3da0-49a3-8603-ff62caa0cc5d" width="100%" height="525px" frameborder="0" scrolling="no"></iframe>
      


      {/* How It Works Section */}
      <div className="relative overflow-hidden min-h-screen">
      <div className="absolute inset-0 bg-grid-black/[0.02] [mask-image:radial-gradient(white,transparent_70%)]" />
      
      <div className="container relative px-4 py-16 mx-auto">
        <div className="grid items-center gap-12 lg:grid-cols-2">
          <div className="flex flex-col gap-6">
            <div className="space-y-4">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="inline-flex items-center gap-2 px-4 py-2 text-sm text-white bg-black rounded-full"
              >
                <Sparkles className="w-4 h-4" />
                <span>Discover the new way to connect</span>
              </motion.div>
              
              <motion.h2
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.1 }}
                className="text-4xl font-bold tracking-tight lg:text-5xl text-gray-900"
              >
                Experience Our Apps
              </motion.h2>
              
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="text-xl text-gray-600"
              >
                Explore both Mémow and Mémow Partners for the complete experience. Connect, book, and create memories together.
              </motion.p>
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.3 }}
              className="flex flex-col gap-4 sm:flex-row"
            >
              <button 
                className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
                onClick={()=>{Nav('/coming-soon')}}
              >
                <Download className="w-4 h-4 mr-2" />
                Download Mémow
              </button>
              <button 
                className="inline-flex items-center justify-center px-6 py-3 text-base font-medium text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                onClick={()=>{Nav('/coming-soon')}}
              >
                <Download className="w-4 h-4 mr-2" />
                Download Mémow Partners
              </button>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              className="flex items-center gap-4 p-4 border border-gray-200 rounded-lg bg-white/50 backdrop-blur"
            >
              <div className="flex gap-1">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="w-5 h-5 fill-yellow-400 text-yellow-400" />
                ))}
              </div>
              <div className="text-sm">
                <p className="font-medium text-gray-900">Have a 5 star User Experience</p>
                <p className="text-gray-600">Leave you reviews</p>
              </div>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.7 }}
            className="relative lg:ml-auto min-h-[500px] flex items-center"
          >
            <div className="relative w-full max-w-[260px] mx-auto lg:max-w-[280px]">
              <div className="relative overflow-hidden rounded-[2.5rem] shadow-xl">
                <img
                  src={phoneImage}
                  alt="App screenshot"
                  fill
                  className="object-cover"
                  priority
                />
              </div>
            </div>
              
              <div className="absolute -bottom-6 -left-6 h-40 w-40 bg-black/5 rounded-full blur-3xl" />
              <div className="absolute -top-6 -right-6 h-40 w-40 bg-black/5 rounded-full blur-3xl" />
          </motion.div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Home;
