import React, { useState, useRef, useEffect } from 'react';
import './style.css'; // Import your CSS file for styling
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Use Routes in place of Switch
import Home from './Home'; // Home component with all required sections
import Services from './Services'; // Separate Services component
import logo from './assets/logo.png';
import Form from './Form';
import ComingSoon from './ComingSoon';
import { ChevronDown, Home as Myhome, Calendar, Briefcase, Menu, X, History } from "lucide-react"
import Weadding from './weaddingfrom';
import Contactus from './contact';
import Booking from './booking';
import { Phone, Mail, MapPin } from "lucide-react"
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
import { motion, AnimatePresence } from 'framer-motion'
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './terms';
import { useLocation } from 'react-router-dom';
import AfterBooking from './afterBokking';
import OrderHistory from './pastbookig';

const menuItems = [
  {
    icon: <Calendar className="w-5 h-5" />,
    label: 'Wedding',
    subItems: [
      { label: 'Wedding Day', link: '/wedding-form' },
      { label: 'Wedding Week', link: '/wedding-form' },
    ],
  },
  {
    icon: <Calendar className="w-5 h-5" />,
    label: 'Occasions',
    subItems: [
      { label: 'Pre Wedding', link: '/wedding-form' },
      { label: 'Maternity', link: '/wedding-form' },
      { label: 'Baby Shower', link: '/wedding-form' },
      { label: 'Parties & Events', link: '/wedding-form' },
    ],
  },
  {
    icon: <Calendar className="w-5 h-5" />,
    label: 'Business',
    subItems: [
      { label: 'Brand Shoot', link: '/wedding-form' },
      { label: 'Croporate Profile & Headshot', link: '/wedding-form' },
      { label: 'Political Profile & Headshot', link: '/wedding-form' },
      { label: 'Portfolio', link: '/wedding-form' },
    ],
  },
  { icon: <History className="w-5 h-5" />, label: 'Past Bookings', link: '/past-booking' },
  { icon: <Briefcase className="w-5 h-5" />, label: 'Services', link: '/services' },
  { icon: <Myhome className="w-5 h-5" />, label: 'Home', link: '/' },
]


const App = () => {
  const [Category,setCategory] = useState('');
  function handleCategory(Category){
    setCategory(Category)
  }

  const ScrollToTop = () => {
    // Extracts pathname property(key) from an object
    const { pathname } = useLocation();
  
    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  }
    
  const [isOpen, setIsOpen] = useState(false)
  const [activeSubmenu, setActiveSubmenu] = useState(null)
  const menuRef = useRef(null)

  const toggleMenu = () => setIsOpen(!isOpen)

  const handleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index)
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false)
        setActiveSubmenu(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  
  return (
    <Router>
      <div>
      <ScrollToTop />
        {/* Header */}
        <header className="header">
          <div className="header-container">
             {/* Logo with image and text */}
             <div className="logo-section">
              <img src={logo} alt="Logo" className="logo-icon" />
              <div className="logo-text">Mémow</div>
            </div>

            {/* Navigation Links */}
            <nav className="collapse md:visible flex gap-8 ml-auto mr-5">
              {/* <Link to="/" className="nav-link">Home</Link>
              <Link to="/services" className="nav-link">Services</Link> Link to Services Page */}
              <Link to="/" className="text-white hover:text-[#c29112]">Home</Link>
              <Link to="/services" className="text-white hover:text-[#c29112]">Services</Link>
              <div className="relative group">
                  <button className="text-white hover:text-[#c29112] flex items-center">
                    Wedding <ChevronDown className="ml-1 h-4 w-4" />
                  </button>
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 ease-in-out">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Wedding Day")}>Wedding Day</Link>
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Wedding Week")}>Wedding Week</Link>
                    </div>
                  </div>
               </div>
              <div className="relative group">
                  <button className="text-white hover:text-[#c29112] flex items-center">
                    Occasions <ChevronDown className="ml-1 h-4 w-4" />
                  </button>
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 ease-in-out">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Pre Wedding")}>Pre Wedding</Link>
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Maternity")}>Maternity</Link>
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Baby Shower")}>Baby Shower</Link>
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Parties & Events")}>Parties & Events</Link>
                    </div>
                  </div>
               </div>
               <div className="relative group">
                  <button className="text-white hover:text-[#c29112] flex items-center">
                  Business <ChevronDown className="ml-1 h-4 w-4" />
                  </button>
                  <div className="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 ease-in-out">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Brand Shoot")}>Brand Shoot</Link>
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Croporate Profile & Headshot")}>Croporate Profile & Headshot</Link>
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Political Profile & Headshot")}>Political Profile & Headshot</Link>
                      <Link to="/wedding-form" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => handleCategory("Portfolio")}>Portfolio</Link>
                    </div>
                  </div>
               </div>
               <SignedIn>
               <Link to="/past-booking" className="text-white hover:text-[#c29112]">Past Booking</Link>
               </SignedIn>
            </nav>

            {/* Notify Me Button */}
            <SignedOut>
              <SignInButton mode="modal">
                <button className="absolute right-2 md:relative bg-[#c29112] text-white py-1 px-3 sm:py-2 sm:px-4 rounded text-sm sm:text-base">
                  Sign In
                </button>
              </SignInButton>
            </SignedOut>
            <SignedIn>
              <button className="absolute right-2 md:relative">
              <UserButton />
              </button>
            </SignedIn>
          </div>
        </header>

    
      <div className="visible md:invisible fixed bottom-6 right-6 z-50" ref={menuRef}>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="mb-4 space-y-2"
          >
            {menuItems.map((item, index) => (
              <div key={index} className="relative">
                {item.subItems ? (
                  <button
                    onClick={() => handleSubmenu(index)}
                    className="w-full flex items-center justify-between px-4 py-2 bg-white text-gray-800 rounded-lg shadow-md hover:bg-gray-100 transition-colors"
                  >
                    <span className="flex items-center">
                      {item.icon}
                      <span className="ml-2">{item.label}</span>
                    </span>
                    <ChevronDown className={`w-4 h-4 transition-transform ${activeSubmenu === index ? 'rotate-180' : ''}`} />
                  </button>
                ) : ( item.label == "Past Bookings" ?
                <SignedIn>
                  <Link
                    to={item.link}
                    className="w-full flex items-center px-4 py-2 bg-white text-gray-800 rounded-lg shadow-md hover:bg-gray-100 transition-colors"
                    onClick={toggleMenu}
                  >
                    {item.icon}
                    <span className="ml-2">{item.label}</span>
                  </Link></SignedIn> : 
                    <Link
                    to={item.link}
                    className="w-full flex items-center px-4 py-2 bg-white text-gray-800 rounded-lg shadow-md hover:bg-gray-100 transition-colors"
                    onClick={toggleMenu}
                  >
                    {item.icon}
                    <span className="ml-2">{item.label}</span>
                  </Link>
                )}
                {item.subItems && activeSubmenu === index && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                    className={`absolute ${
                      index < menuItems.length / 2 ? 'bottom-full mb-2' : 'top-full mt-2'
                    } left-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
                  >
                    <div className="py-1" role="menu" aria-orientation="vertical">
                      {item.subItems.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          to={subItem.link}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          onClick={()=>{toggleMenu()
                            handleCategory(subItem.label)
                          }}
                        >
                          {subItem.label}
                        </Link>
                      ))}
                    </div>
                  </motion.div>
                )}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      <motion.button
        whileTap={{ scale: 0.95 }}
        onClick={toggleMenu}
        className="p-4 bg-primary text-white rounded-full shadow-lg focus:outline-none hover:bg-primary-dark transition-colors"
        aria-label={isOpen ? "Close menu" : "Open menu"}
      >
        {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </motion.button>
    </div>

        {/* Routing Configuration */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/notify" element={<Form />} /> {/* Route to the form */}
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="/wedding-form" element={<Weadding category={`${Category}`}/>} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/after-booking" element={<AfterBooking />} />
          <Route path="/past-booking" element={<OrderHistory />} />
        </Routes>
        
        {/* Footer */}
      <footer className="bg-black text-white py-8 px-4 z-50">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          <div>
            <h2 className="text-xl font-bold mb-4">Company</h2>
            <ul className="space-y-2">
            <Link to="/terms-and-conditions" className="hover:underline font-light"><li>Terms and Conditions</li></Link>
              <li><Link to="/privacy-policy" className="hover:underline font-light">Privacy Policy</Link></li>
              <li><Link href="#" className="hover:underline font-light">FAQ/Support</Link></li>
            </ul>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-4">Social Media</h2>
            <div className="flex space-x-4">
              <a href="https://www.instagram.com/memow.it/" className="hover:opacity-80">
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/>
                </svg>
                <span className="sr-only">Instagram</span>
              </a>
              <a href="https://www.linkedin.com/company/memow/" className="hover:opacity-80">
                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/>
                </svg>
                <span className="sr-only">LinkedIn</span>
              </a>
            </div>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-4">Contact Us</h2>
            <ul className="space-y-2">
              <li className="flex items-center font-thin"><Phone className="w-4 h-4 mr-2 " /> +91 87777 29704</li>
              <li className="flex items-center font-thin"><Phone className="w-4 h-4 mr-2 " /> +91 8240772369</li>
              <li className="flex items-center font-thin"><Mail className="w-4 h-4 mr-2 " /> connect@memowries.com</li>
            </ul>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-4">Our Locations</h2>
            <ul className="space-y-4">
              <li className="flex items-start font-thin">
                <MapPin className="w-4 h-4 mr-2 mt-1 flex-shrink-0" />
                <span>9C8G+HF2 Baruipur, Kolkata - 700144</span>
              </li>
              <li className="flex items-start font-thin">
                <MapPin className="w-4 h-4 mr-2 mt-1 flex-shrink-0" />
                <span>187 Chowheshwari Nilaya, Bangalore - 560035, Karnataka</span>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-4">Download App</h2>
            <button className="bg-yellow-600 text-white px-4 py-2 rounded hover:bg-yellow-700 transition duration-300">
              Notify Me
            </button>
          </div>
        </div>
        <div className="mt-8 pt-4 border-t border-gray-700 text-sm text-gray-400">
          Copyright © Integrated Bubble Pvt. Ltd.
        </div>
      </div>
    </footer>
      </div>
    </Router>
  );
};

export default App;
